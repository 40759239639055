import {
  Box,
  Button,
  Grid,
  Alert,
  AlertColor
} from "@mui/material";
import { ReactElement, useState, useEffect } from 'react';
import queryString, { ParsedQuery } from "query-string";
import logo from "../../assets/images/cocheseguro_logo.svg";
import Loading from '../../components/Loading';
import { ParamsZurichProps } from "../../models/paymentRequest";

export const PanelZurich: React.FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [typeNameDocument, setTypeNameDocument] = useState<string>("");
  const [params, setParams] = useState<ParamsZurichProps>({
    policyId: "",
    trackingId: "",
  });
  const [message, setMessage] = useState<{value: string, type: string}>({
    value: "",
    type: "", 
  });
  const interServicesurl: string = process.env.REACT_APP_SERVICE_AIG_URL || 'https://demos.inter.mx/v1/';
  const redirectUrl: string = 'https://cocheseguro.inter.mx'

  useEffect((): void => {
    const token: string | null = localStorage.getItem('token');
    if (!token) {
      getAccessToken();
    }
    processQueries()
  }, []);

  const processQueries = (): void => {
    try {
      const params: ParsedQuery<string> = queryString.parse(window.location.search);
      setParams({
        policyId: params["q"],
        trackingId: params["tr"],
      } as ParamsZurichProps);
    } catch (error) {
      setMessage({
        value: "Hubo un error al procesar la información. Si el problema persiste contacte a soporte.",
        type: "error"
      });
      hideMessage();
    }
  }

  const getAccessToken = async(): Promise<void> => {
    const url = `${interServicesurl}authorization/token`;
    console.log("url", url);
    const body = {
      "client_id": process.env.REACT_APP_CLIENT_ID,
      "client_secret": process.env.REACT_APP_CLIENT_SECRET
    };
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json());
      console.log("Response", res);
      if (res.success && res.data.access_token) {
        localStorage.setItem('token', res.data.access_token);
      } else {
        setMessage({
          value: `Ha ocurrido un error al obtener el token. Si el problema persiste contacte a soporte.`,
          type: "error",
        });
        hideMessage();
      }
    } catch (error) {
      setMessage({
        value: `Ha ocurrido un error al obtener el token. Si el problema persiste contacte a soporte.`,
        type: "error",
      });
      hideMessage();
    }
  }
    
  // Download a file from the server through a GET request.
  const downloadFile: any = async (url: string, name: string): Promise<void> => {
    fetch(url, {
      method: 'GET',
    }).then(res => res.blob()).then(blob => {
      const url: string = window.URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = url;
      a.download = `${params.policyId}-${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsLoading(false);
    }
    ).catch(error => {
      setMessage({
        value: `Ha ocurrido un error al descargar el PDF de ${name}. Si el problema persiste contacte a soporte.`,
        type: "error",
      });
      hideMessage();
      setIsLoading(false);
    });
  }

  const sendHttpRequest: any = async ({type, name}: {
    type: string, 
    name: any
  }): Promise<void> => {
    setIsLoading(true);
    setTypeNameDocument(name);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    myHeaders.append("Content-Type", "application/json");

    const raw: string = JSON.stringify({
      "Poliza": params.policyId,
      "tracking_id": params.trackingId,
      "insurance": "zurich"
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    await fetch(`${interServicesurl}pdf/${type}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(!result.success) {
          setMessage({
            value: `Ha ocurrido un error al obtener el PDF de ${name}. Si el problema persiste contacte a soporte.`,
            type: "error",
          });
          hideMessage();
          setIsLoading(false);
          return
        }
        downloadFile(result.data.policy_url || result.data.receipts_url, name);
      })
      .catch(error => {
        setMessage({
          value: `Ha ocurrido un error al obtener el PDF de ${name}. Si el problema persiste contacte a soporte.`,
          type: "error",
        });
        hideMessage()
        setIsLoading(false);
      });
  }
    
  const hideMessage = (): void => {
    setTimeout(() => {
      setMessage({
        value: "",
        type: "",
      });
    }, 3000);
  }

  return (
    <Box>
      {isLoading ? <Loading text={`Descargando ${typeNameDocument}.`}></Loading> : null}
      {message.value ? <Alert severity={message.type as AlertColor}>{message.value}</Alert>: null}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        paddingX={5}
        sx={{ backgroundColor: "#00BBD4" }}
        >
        <Box>
          <img 
            src={logo} 
            width={100} 
            height={100} 
            alt="logo de cocheseguro"
          />
        </Box>
        <Box>
          <Button
            variant="contained" 
            style={{backgroundColor: "#F5F5F5", fontSize: "14px",  fontWeight: "bold", color: "#039ECC" }}
            onClick={(): void => { window.location.href = redirectUrl }}
          >
            Cocheseguro
          </Button>
        </Box>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: "85vh", display: "flex", backgroundColor: "#F5F5F5" }}
        display="flex"
      >
        <Box 
          paddingX={5}
        >
          <h2
            style={{
              color: "#E7067F",
              fontSize: "32px",  
            }}
          >No. Póliza. <br/><br/> {params.policyId}</h2>  
        </Box>
        <Grid item xs={3} display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
          <Box
            paddingX={5}
            marginBottom={7}
          >
            <Button 
              variant="contained" 
              style={{backgroundColor: "#039ECC", fontSize: "14px",  fontWeight: "bold" }}
              onClick={(): void => {sendHttpRequest({type: "policy", name: "poliza"})}}
            >
              Descargar poliza
            </Button>
          </Box>
          <Box
            paddingX={5}
            marginBottom={7}
          >
            <Button 
              variant="contained" 
              style={{backgroundColor: "#039ECC", fontSize: "14px",  fontWeight: "bold" }}
              onClick={(): void => {sendHttpRequest({type: "receipts", name: "recibos"})}}  
            >
              Descargar Recibos
            </Button>
          </Box>
        </Grid>
      </Grid> 
    </Box>
  );
}

import React from 'react'

import {
  Box,
  Typography,
  CircularProgress
} from "@mui/material";
function Loading(props: {text?: string}) {
  let text = props.text;
  text ??= '';
  return (
    <Box 
      display= {'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      position= {'fixed'}
      right= {'0'}
      left= {'0'}
      top= {'0'}
      height= {'100%'}
      width= {'100%'}
      style={{zIndex: '10', backgroundColor: 'rgba(0,0,0,0.5)'}}
      >
        <CircularProgress 
        color="secondary"
        style={{height: '100px', width: '100px'}} 
        />
        <Typography
            textAlign="center"
            fontSize={'20px'}
            color={'white'}
          >
            {text}
          </Typography>
      </Box>
  )
}

export default Loading

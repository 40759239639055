import {
	Theme,
	Container,
	Box,
	Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataCard } from "../screens/payment/DataCard";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		paddingBottom: "60px",
	},
}));

export const Payment: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth={"lg"}>
      <Grid container marginTop={2}>
						<Grid item xs={12} md={12} lg={12}>
							<Box>
								<DataCard></DataCard>
							</Box>
						</Grid>
			</Grid>
      </Container>
    </div>
  )
}

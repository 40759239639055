import { ReactElement, useState, useEffect } from "react";
import queryString, { ParsedQuery } from "query-string";
import { Alert, AlertColor } from "@mui/material";
import Loading from "../../components/Loading";
import { decode } from "../../general/utils";

export const ResponseInsurance: React.FC = (): ReactElement => {
  const [message, setMessage] = useState<{value: string, type: string}>({
    value: "",
    type: "", 
  });
  const interServicesurl = process.env.REACT_APP_SERVICE_AIG_URL || 'https://demos.inter.mx/v1/';
  //const [redirectUrl] = useState<string>("https://cocheseguro.inter.mx/welcome2");
  const [redirectUrl] = useState<string>("https://inter-cocheseguro-qa.web.app/welcome2");

  useEffect((): void => {
    setTimeout(handlePaymentResponse, 1500);
  }, []);

  function processQueries(): ParsedQuery<string> {
    try {
      const params: ParsedQuery<string> = queryString.parse(window.location.search);
      const decoded: ParsedQuery<string> = JSON.parse(decode(params["q"] as string));
      delete params["q"];
      return { ...decoded, ...params, url: window.location.href };
    } catch (error) {
      setMessage({
        value: "Hubo un error al procesar la información. Si el problema persiste contacte a soporte.",
        type: "error"
      });
      throw error
    }
  }

  function saveResponse(responsePayment: ParsedQuery<string>): void {
    const url = `${interServicesurl}${responsePayment.business}/${responsePayment.insurance}/payment`;

    // console.log("RESPONSE PAYMENT", responsePayment)

    // responsePayment.payment_id = "nUp1G8PQvJaQRlrpJdQv"      
    // responsePayment.emission_id = "IPk5xrN3ziuyK1nTnBJ3"
    // responsePayment.quote_id = "D7176DlgJK4y36EiQNHd"

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(responsePayment),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
      .then(response => {
        console.log("Response", response)
        if (response.code !== 200) {
          setMessage({
            value: response.message,
            type: "error"
          });
          return;
        }
        if(response.code === 200 && !response.success) {
          setMessage({
            value: response.message,
            type: "warning"
          });
          setTimeout(async () => {
            window.parent.postMessage(JSON.stringify({ result: "Error" }), '*');
            window.location.href = await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
            // await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
          }, 2000);
          return;
        } else if(response.code === 200 && response.success) {
          setMessage({
            value: response.message,
            type: "success"
          });
          
          sendPostEmission(responsePayment);
        }
      })
      .catch(error => {
        setMessage({
          value: "Ha ocurrido un error al procesar la información. Si el problema persiste contacte a soporte.",
          type: "error"
        });
        setTimeout(async () => {
          window.parent.postMessage(JSON.stringify({ result: "Error" }), '*');
          window.location.href = await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
          // await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
        }, 2000);
      });
  }

  function sendPostEmission(responsePayment: ParsedQuery<string>): void {
    const data = { 
      emissionDocumentId: responsePayment.emission_id,
      quoteDocumentId: responsePayment.quote_id,
      paymentDocumentId: responsePayment.payment_id,
      business: responsePayment.business,
      insurance: responsePayment.insurance,
      tracking_id: responsePayment.tracking_id,
    }

    const url = `${interServicesurl}${responsePayment.business}/${responsePayment.insurance}/post-emission`;
    
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .catch(error => {
      setMessage({
        value: "Hubo un error al procesar la información. Si el problema persiste contacte a soporte.",
        type: "error"
      });
      
      setTimeout(async () => {
        window.parent.postMessage(JSON.stringify({ result: "Error" }), '*');
        window.location.href = await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
        // await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
      }, 2000);
    })
    .then(response => {
      if (!response.success) {
        setMessage({
          value: "Ha ocurrido un error al obtener el número de poliza, si el problema persiste contacte a soporte.",
          type: "error"
        });
        setTimeout(async () => {
          window.parent.postMessage(JSON.stringify({ result: "Error" }), '*');
          window.location.href = await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
          // await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
        }, 2000);
        return;
      }

      handlePaymentSuccess(response.data.policy_id, responsePayment);
    });
  }

  function handlePaymentSuccess(policy: string, responsePayment: ParsedQuery<string>): void {
    const paymentResult = {
      result: 'Success',
      policy
    }

    setTimeout(async () => {
      window.parent.postMessage(JSON.stringify(paymentResult), '*');
      window.location.href = await getRedirectUrl(responsePayment.Aut?.toString() || "", responsePayment);
    }, 2000);
  }

  function handlePaymentResponse(): void {
    const response: ParsedQuery<string> = processQueries();
    saveResponse(response);
  }

  async function getRedirectUrl(statusPayment: string, responsePayment: ParsedQuery<string>): Promise<string> {

    if(statusPayment !== "2" && statusPayment !== "3" && responsePayment.business === "cocheseguro") {
      return redirectUrl;
    }
    
    const redirect = `${interServicesurl}redirect`;
    const body = {
      policy_id: responsePayment.policy_id,
      business: responsePayment.business,
      insurance: responsePayment.insurance,
      paymentId: responsePayment.payment_id,
      tracking_id: responsePayment.tracking_id,
      emissionId: responsePayment.emission_id,
    }

    try {
      const response = await fetch(redirect, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json();
      if (data.success) {
        return data.data;
      }

      setMessage({
        value: "Ha ocurrido un error al obtener la información. Si el problema persiste contacte a soporte.",
        type: "error"
      });

    } catch (error) {
      setMessage({
        value: "Ha ocurrido un error al obtener la información. Si el problema persiste contacte a soporte.",
        type: "error"
      });
    }

    // if(statusPayment === "1" || statusPayment === "2" || statusPayment === "3") {
    //   return redirectUrl;
    // }
    // return redirectUrl + "2"

    return ""
  }

  return (
    <div>
      { message.value ? <Alert severity={message.type as AlertColor}>{message.value}</Alert> : <Loading text="Procesando el pago..." /> }
    </div>
  );
}

import { Buffer } from "buffer";
import { JSEncrypt } from "jsencrypt";
import Payment from "payment";

const rsaPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB4fuzDQ8n1PxrguDMgoajl
Xg+ItdzUxmhlv3ne+jnw5CAG7pJDDuxn4LuKmSKJfa0Mm3S/2PynvclHEH8ndty7
jnuiK6D2o8cMG2nw4JU3km/3cNPq2+tOA4a8QJmc5ADjvyZuQJJciRb80EUcBXIb
cF4//zSOhVe3x14yE6vPWZ+CcDvXqL1+mGdnDL40oWYk1J4eDSQDr43YNTX8xnoQ
nxdRuqG7AaoIzUSVzG8N3jJHM1q3+G/jCheKyZVvSt9rAhEkRxE+6X38or2bMsa3
z4/VKCiuTqwvfTMCaWQKGYKSYW3QBaWYR+8EDa2m17dncxmWPXBpAMJ274szOy2P
AgMBAAE=
-----END PUBLIC KEY-----`;

export function decode(data: string): string {
  data = data.replace(/-/g, "+").replace(/_/g, "/");
  while (data.length % 4) data += "=";

  return Buffer.from(data, "base64").toString("utf-8");
}

export function encryptRsaWithPublicKey(data: string): string | false {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(rsaPublicKey);

  return encrypt.encrypt(data);
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(value: string): string {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatCVC(value: string): string {
  let maxLength = 4;
  const clearValue = clearNumber(value);
  if (!clearValue) return clearValue.slice(0, maxLength);
  const issuer = Payment.fns.cardType(clearValue);
  maxLength = issuer === "amex" ? 4 : 4;

  return clearValue.slice(0, maxLength);
}

export function formatCreditCardNumber(value: string): any{
  const clearValue = clearNumber(value);
  const issuer = Payment.fns.cardType(clearValue);
  let nextValue;
  let result;
  const maxLength = 16;


  console.log(issuer);

  if (['amex', 'dinersclub'].includes(issuer)) {
    console.log("primero");
    const format = [0, 4, 10];
    const limit = [4, 6, 5];
    nextValue = `${clearValue.substr(format[0], limit[0])} ${clearValue.substr(format[1], limit[1])} ${clearValue.substr(format[2], limit[2])}`;

    result = nextValue.trim();
  }
  else if (clearValue.length > 16) {
    console.log("Segundo");
    const format = [0, 4, 8, 12];
    const limit = [4, 6];
    nextValue = `${clearValue.substr(format[0], limit[0])} ${clearValue.substr(format[1], limit[0])} ${clearValue.substr(format[2], limit[0])} ${clearValue.substr(format[3], limit[1])}`;

    result = nextValue.trim();
  }
  else {
    for (let i = 1; i < (maxLength / 4); i++) {
      const space_index = (i * 4) + (i - 1);
      nextValue = `${clearValue.slice(0, space_index)} ${clearValue.slice(space_index)}`;
      result = nextValue.trim();
    }
  }

  // if (issuer === 'amex') {
  //   nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
  //     4,
  //     10
  //   )} ${clearValue.slice(10, 15)}`;
  // } else {
  //   result = Payment.fns.formatCardNumber(clearValue);
  // }

  return result;
}

export function validateCvc(value: string, cardNumber?: string): boolean {
  let cardType = cardNumber ? Payment.fns.cardType(value) : undefined;
  return Payment.fns.validateCardCVC(value, cardType);
}

export function validateCreditCardNumber(value: string): boolean {
  return Payment.fns.validateCardNumber(value);
}

export function validateExpiry(value: string): boolean {
  return Payment.fns.validateCardExpiry(value);
}
